import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './DropDown.module.scss';

function MenuList({ data, onChange, testRef, currentLetter }) {
  const list = useRef(null);
  const [hasScroll, setHasScroll] = useState(false);

  const handleScroll = () => {
    if (list) {
      if (testRef.current.scrollTop > 0) {
        return setHasScroll(true);
      }
      return setHasScroll(false);
    }
  };

  return (
    <ul className={`${styles.menu}  ${data && !data.length ? styles.empty:""}`}>
      <div
        className={styles.inner}
        style={{
          boxShadow: hasScroll ? 'inset 0px 5px 10px -9px rgba(0,0,0,0.5)' : '',
        }}
      >
        <div
          className={styles.list}
          ref={list}
          onScroll={handleScroll}
          ref={testRef}
        >
          {data &&
            data.map((curr, index) => (
              <Item
                {...curr}
                onClick={onChange ? () => onChange(curr) : void 0}
                key={curr.flag + index}
                currentLetter={currentLetter}
              />
            ))}
        </div>
      </div>
    </ul>
  );
}
function Item({ flag, name, code, onClick, currentLetter }) {
  return (
    <li
      onClick={onClick}
      id={(() => {
        if (code[0] !== currentLetter.current) {
          currentLetter.current = code[0];
          return code[0];
        }
        return undefined;
      })()}
    >
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <span className={styles["li-flag"]}>
          <div
            className={styles.flag}
            style={{ backgroundImage: `url(${flag})` }}
          ></div>
        </span>
      </span>
      <span>{code}</span>
    </li>
  );
}
MenuList.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

Item.propTypes = {
  flag: PropTypes.string,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
export default MenuList;
