import React from 'react';
import styles from './scss/formState.module.scss';
import { ReactComponent as Checked } from '../../assets/svg/checked.svg';
import { ReactComponent as Imp } from '../../assets/svg/important.svg';
import { ReactComponent as Error } from '../../assets/svg/Error.svg';
//import PropTypes from 'prop-types'

function FormState({ type, message, noIcon }) {
  const iconSchema = {
    loading: (
      <span>
        <i className='fa fa-spinner fa-spin'></i>
      </span>
    ),
    info: <Imp />,
    warning: (
      <span>
        <i className='fa fa-cry'></i>
      </span>
    ),
    error: <Error />,
    okay: <Checked />,
  };
  const colorSchema = {
    loading: 'black',
    info: '#04A339',
    warning: 'brown',
    error: '#DC0000',
    okay: '#5E6061',
    preli:"#131314"
  };
  const styleObj = { color: colorSchema[type] || '#0000' };

  return (
    <div className={`${styles.info} ${noIcon ? styles.no_mt :""}`}>
      <span style={styleObj} className={styles.icon}>
        {!noIcon && iconSchema[type] && iconSchema[type]}
      </span>
      <p style={styleObj}>{message}</p>
    </div>
  );
}

FormState.propTypes = {};

export default FormState;
