import {
  UPDATE_TRANSACTION,
  TRANSACTION_REQUEST,
  TRANSACTION_REQUEST_SUCCESS,
  TRANSACTION_ERROR,
  CHANGE_TRANSACTION_TYPE,
  CLEAR_ERROR,
  CART_SUCCESS,
  TOGGLE_USECART,
  ORDER_VERIFY_SUCCESS,
  CLEAR_TRANSACTIONS,
  CREATE_CUSTOMER,
} from "./transaction.constants";
export function transactionReducer(state, action) {
  switch (action.type) {
    case UPDATE_TRANSACTION:
      const {
        tradingPair,
        quantity,
        estimatedFee,
        locked,
        currencies,
      } = action.payload;
      return {
        ...state,
        transaction: {
          ...state.transaction,
          tradingPair,
          quantity,
          locked,
          estimatedFee,
        },
        currencies,
      };
    case TRANSACTION_REQUEST:
      return { ...state, loading: true };
    case TRANSACTION_REQUEST_SUCCESS:
      return { ...state, loading: false };
    case CREATE_CUSTOMER:
      return { ...state, loading: false, customer: action.payload };
    case TRANSACTION_ERROR:
      return { ...state, loading: false, error: action.payload };
    case CART_SUCCESS:
      return { ...state, cart: action.payload };
    case TOGGLE_USECART:
      return { ...state, useCart: !state.useCart };
    case CLEAR_ERROR:
      return { ...state, error: "" };
    case CHANGE_TRANSACTION_TYPE:
      return { ...state, transactionType: action.payload };
    case ORDER_VERIFY_SUCCESS:
      return { ...state, loading: false, order: action.payload };
    case CLEAR_TRANSACTIONS:
      return { ...state, loading: false, order: {}, cart: {}, currencies:{}, transaction:{} };
    case "CLEAR_CART":
      return { ...state,  cart: {} };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
