import React from 'react';
import Oldstyle from '../../styles.module.scss';
import styles from './scss/styles.module.scss';
import cx from 'classnames';

function Final({ openChat }) {
  return (
    <div className={styles.verify}>
      <div className={styles.view_wallet}>
        <h4 className={styles.title}>FINAL STEP</h4>
        <div className={styles.centered}>
          <p className={styles.pinfo}>
            Naira will be paid to your account after confirmation.
          </p>
          <button className={cx(Oldstyle.btn_action)} style={{ marginTop: 8 }}>
            CHAT WITH US
          </button>
          <div className={styles.chatlink}>
            <span>
            <a href="https://api.whatsapp.com/send?phone=2347042272413" target="_blank"><i className="fa fa-whatsapp" ></i>
            </a>
            </span>
           
            <span onClick={openChat}>
              <i className="fa fa-comment"></i>
            </span>
          </div>
          <h5 className={styles.checkpoint}>
            Thank you for choosing Afriswap.
          </h5>
        </div>
      </div>
    </div>
  );
}

Final.defaultProps={
  openChat:()=>{}
}

Final.propTypes = {};

export default Final;
