import { findMinutesDiff } from '../../helpers';
import { checkedJsonStorage } from '../../utils';

import _ from 'lodash';
import {
  TRANSACTION_ERROR,
  UPDATE_TRANSACTION,
  CART_SUCCESS,
  TRANSACTION_REQUEST_SUCCESS,
  TRANSACTION_REQUEST,
  CHANGE_TRANSACTION_TYPE,
  ORDER_VERIFY_SUCCESS,
  CREATE_CUSTOMER,
  CLEAR_ERROR,
  TOGGLE_USECART,
  CLEAR_TRANSACTIONS,
} from './transaction.constants';
import { transactionService } from './transaction.service';

/**
 *
 * @param {*} dispatch
 * @param {*} estimatedFee
 * @param {*} quantity
 * @param {*} tradingPair
 * @param {*} currencies
 * @param {*} type
 */
export function updateTransaction(
  dispatch,
  estimatedFee,
  quantity,
  tradingPair,
  locked,
  currencies
) {
  dispatch({
    type: UPDATE_TRANSACTION,
    payload: { estimatedFee, quantity, tradingPair, currencies, locked },
  });
}

/**
 *
 * @param {*} tdispatch
 * @param {*} transaction
 * @param {*} type
 */
export function createTransaction(tdispatch, transaction, type = 'buy') {
  tdispatch({ type: TRANSACTION_REQUEST });
  return new Promise((resolve, _) => {
    transactionService
      .createTransaction(transaction, type)
      .then((data) => {
        tdispatch({ type: TRANSACTION_REQUEST_SUCCESS });
        tdispatch({ type: CART_SUCCESS, payload: data });
        resolve(data);
      })
      .catch((error) => {
        handleResponseError(tdispatch, error);
      });
  });
}

/**
 *
 * @param {*} dispatch
 * @param {*} cart
 * @param {*} type
 */
export function createOrder(dispatch, cart, type = 'buy') {
  dispatch({ type: TRANSACTION_REQUEST });

  return new Promise((resolve, reject) => {
    transactionService
      .createOrder(cart, type)
      .then((order) => {
        dispatch({ type: TRANSACTION_REQUEST_SUCCESS });
        //take user to paystack portal
        if (type.toLowerCase() === 'sell') {
          return resolve('ok');
        }
        window.location.href = order.authorization_url;
      })
      .catch((error) => {
        handleResponseError(dispatch, error);
      });
  });
}

/**
 *
 * @param {*} dispatch
 * @param {*} trxref
 */
export function verifyOrder(dispatch, trxref) {
  dispatch({ type: TRANSACTION_REQUEST });
  return new Promise((resolve, reject) => {
    transactionService
      .verifyOrder(trxref)
      .then((order) => {
        dispatch({ type: TRANSACTION_REQUEST_SUCCESS });
        dispatch({ type: ORDER_VERIFY_SUCCESS, payload: order });
        resolve('ok');
      })
      .catch((error) => {
        handleResponseError(dispatch, error);
      });
  });
}

/**
 *
 * @param {*} dispatch
 */
export function toggleUseCart(dispatch) {
  dispatch({ type: TOGGLE_USECART });
}

/**
 *
 * @param {*} dispatch
 */
export function clearError(dispatch) {
  dispatch({ type: CLEAR_ERROR });
}

/**
 *
 * @param {*} dispatch
 */
export function clearTransaction(dispatch) {
  localStorage.removeItem('cart');
  localStorage.removeItem('order');
  localStorage.removeItem('currStep');
  dispatch({ type: CLEAR_TRANSACTIONS });
}

export function clearExpiredCart(tDispatch) {
  //read cart
  const cart = checkedJsonStorage('cart');
  if (!cart || _.isNull(cart) || _.isEmpty(cart)) return; //cart is empty
  const today = new Date();
  const cartLastUpdate = new Date(cart.updatedAt);
  const isExpired = findMinutesDiff(today, cartLastUpdate) > 10;
  function clear(){
    if (isExpired) {
      localStorage.removeItem('cart');
      tDispatch({ type: 'CLEAR_CART' });
    }
}
  return {
      isExpired,
      clear,
  }
}

/**
 *
 * @param {*} tdispatch
 * @param {*} customer
 */
export function createVerify(tdispatch, customer) {
  tdispatch({ type: TRANSACTION_REQUEST });
  return new Promise((resolve, _) => {
    transactionService
      .createVerify(customer)
      .then((ok) => {
        tdispatch({ type: TRANSACTION_REQUEST_SUCCESS });
        resolve('ok');
      })
      .catch((error) => handleResponseError(tdispatch, error));
  });
}

/**
 *
 * @param {*} tdispatch
 * @param {*} otp
 * @param {*} sentTo
 */
export function completeVerify(tdispatch, otp, sentTo) {
  tdispatch({ type: TRANSACTION_REQUEST });
  return new Promise((resolve, reject) => {
    transactionService
      .completeVerify(otp)
      .then((ok) => {
        localStorage.setItem('customer', JSON.stringify(sentTo));
        tdispatch({ type: CREATE_CUSTOMER, payload: sentTo });
        resolve('ok');
      })
      .catch((error) => {
        if (error.response && error.response.status === 409){
      tdispatch({
          type: TRANSACTION_ERROR,
          payload:"", 
          // made payload empty to prevent triggering app level error modal, 
          // since the verificationView component wants to handle invalid otp errors
        });
          return reject(
            'You have inputed an incorrect OTP, please input the correct OTP or resend OTP.',
          );
        }
        handleResponseError(tdispatch, error);
      });
  });
}

/**
 *
 * @param {*} tdispatch
 * @param {*} trxref
 * @param {*} data
 */
export function updateSellTransaction(tdispatch, trxref, data) {
  tdispatch({ type: TRANSACTION_REQUEST });
  return new Promise((resolve, reject) => {
    transactionService
      .updateSellTransaction(trxref, data)
      .then((data) => {
        tdispatch({ type: CART_SUCCESS, payload: data });
        tdispatch({ type: TRANSACTION_REQUEST_SUCCESS });
        resolve('ok');
      })
      .catch((error) => handleResponseError(tdispatch, error));
  });
}
export function generateSellWallet(tdispatch, trxref) {
  tdispatch({ type: TRANSACTION_REQUEST });
  return new Promise((resolve, reject) => {
    transactionService
      .generateSellWallet(trxref)
      .then((data) => {
        tdispatch({ type: CART_SUCCESS, payload: data });
        tdispatch({ type: TRANSACTION_REQUEST_SUCCESS });
        resolve('ok');
      })
      .catch((error) => handleResponseError(tdispatch, error));
  });
}

/**
 *
 * @param {*} dispatch
 * @param {*} type
 */
export function changeTransactionType(dispatch, type = "BUY") {
  if(typeof(type) !== "string" && !["buy","sell"].includes(type.toLowerCase()))type = "BUY";
  localStorage.setItem('Trx_type', JSON.stringify(type));
  dispatch({ type: CHANGE_TRANSACTION_TYPE, payload: type });
}
/**
 *
 * @param {*} dispatch
 * @param {*} error
 */
function handleResponseError(dispatch, error) {
  if (error.response) {
    const { message } = error.response.data;
    return dispatch({
      type: TRANSACTION_ERROR,
      payload: message || 'UNKNOWN ERROR',
    });
  }
  dispatch({ type: TRANSACTION_ERROR, payload: error.message });
}
