import React, { useState, useEffect, useRef, Fragment } from 'react';
import { ReactComponent as Logo } from '../assets/img/logo_white.svg';
import LogoBlue from 'logo_blue.png';
import PropTypes from 'prop-types';
//External Module Import
import { useStep } from 'react-hooks-helper';
import queryString from 'query-string';
import cx from 'classnames';
import _ from 'lodash';

//COMPONENT RELATED IMPORT
import {
  WalletVerification,
  ContactVerification,
  BankVerification,
  Final,
  TreviewCard,
  withProvider,
  ViewWallet,
  TransactionState,
  Alert,
  RateBtn,
} from '../components';
import SwapCalculator from '../SwapCalculator';
import styles from './index.module.scss';

//ALL STATE RELATED IMPORTS
import {
  useTransactionDispatch,
  updateTransaction,
  useTransactionState,
  changeTransactionType,
  TransactionProvider,
  verifyOrder,
  clearTransaction,
  clearExpiredCart,
} from '../factory';

import { checkedJsonStorage } from '../utils';

const defaultCryptoImage = {
  ETH: 'https://s3.eu-central-1.amazonaws.com/assets.afriswap.io/ethereum.png',
  USDT:
    'https://s3.eu-central-1.amazonaws.com/assets.afriswap.io/Tether-logo.png',
  BTC: 'https://s3.eu-central-1.amazonaws.com/assets.afriswap.io/bitcoin.png',
};

// STEP CONFIGURATION OF THE APP (BUY AND SELL STEP)
const sellConfig = [
  'CALCULATOR',
  'VERIFICATION',
  'BANK',
  'CONFIRM',
  'VIEWWALLET',
  'FINAL',
];

const buyConfig = ['CALCULATOR', 'WALLET', 'VERIFICATION', 'CONFIRM'];

function FullSwap({ hideChat, callbackUrl, openChat }) {
  //For Transaction Actions
  const tDispatch = useTransactionDispatch();
  const [loadTrxStatus, setLoadTrxStatus] = useState(false);
  const [isTrxStatusLoaded, setIsTrxStatusLoaded] = useState(false);
  const [toggleTrans, setToggleTrans] = useState(false);
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  //Buy Wallet is maintained temporally at top level
  const [wallet, setWallet] = useState('');
  const trxref = useRef(null);
  const handleToggle = () => setToggleTrans(!toggleTrans);
  //parse trxref from url
  const queryParams = useRef(null);
  queryParams.current = queryString.parse(window.location.search);
  //transaction related params
  let { Ttype = '', fiatAmt, q_quantity, qCrypto, qstep, Qlocked } = queryParams.current;
  qstep = Number.isNaN(parseInt(qstep)) ? undefined : parseInt(qstep);
  q_quantity = SafelyParse(q_quantity);
  fiatAmt = SafelyParse(fiatAmt);

   //App navigation from step to step
  const schema = { buy: buyConfig, sell: sellConfig };
  const storedStep = checkedJsonStorage('currStep') || { index: 0 };


  //Get Current Transaction Details From Context state
  const {
    transaction: { estimatedFee, quantity },
    currencies = {},
    transactionType: type,
    order,
    cart,
    isValidTransaction,
  } = useTransactionState();

  const {
    index,
    step,
    navigation: { previous, next, go },
  } = useStep({
    initialStep: typeof qstep !== 'undefined' ? qstep : storedStep.index,
    steps: schema[schema[type.toLowerCase()] ? type.toLowerCase() : 'buy'],
  });

  //Check if there is a redirect from paystack and show transaction status
  useEffect(() => {
    if (queryParams.current && queryParams.current.trxref) {
      setLoadTrxStatus(true);
      verifyOrder(tDispatch, queryParams.current.trxref).then(
        (ok) => setLoadTrxStatus(false),
        setIsTrxStatusLoaded(true),
      );
    }
  }, [trxref.current]);

  //useEffect to track user steps and store in localstorage
  useEffect(() => {
    localStorage.setItem('currStep', JSON.stringify({ index, step }));
    if (
      ['WALLET', 'VERIFICATION', 'BANK'].includes(step) &&
      !isValidTransaction
    ) {
      go(0);
    }
  }, [index]);

  //component  to display transaction status
  function ShowOrderStatus() {
    // Note: All components returned by this function are overlays
    function closeTransactionState() {
      setIsTrxStatusLoaded(false);
      go(0);
      //clear query params
      history.pushState({}, 'rateconveter', window.location.href.split('?')[0]);
    }
    if (loadTrxStatus) {
      return <Alert icon='loading' message='fetching order status...' />;
    }
    if (isTrxStatusLoaded) {
      return <TransactionState {...order} onClick2={closeTransactionState} />;
    }
    return null;
  }

  function SafelyParse(value) {
    return !Number.isNaN(parseFloat(value)) ? parseFloat(value) : 0;
  }

  // dispatch initial transaction intententions
  function handleSwapEvt({ values, currency, crypto, tType, locked }, isUpdate) {
    updateTransaction(tDispatch, values.fiat, values.crypto, crypto.name,locked, {
      c: crypto,
      f: currency,
    });

    //if query params were passed, update them
    if (Ttype || fiatAmt || q_quantity || qCrypto || qstep) {
      history.pushState(
        {},
        'rateconveter',
        `?Ttype=${tType}&fiatAmt=${values.fiat}&qCrypto=${crypto.code}&fiat=${currency.code}`,
      );
    }

    if (isUpdate) return;
    next(); //move to nextview
  }

  function ShowCurrentStep() {
    switch (step) {
      case 'WALLET':
        return (
          <WalletVerification
            type={currencies.c && currencies.c.code}
            onVerified={({ address }) => {
              setWallet(address);
              next();
            }}
          />
        );
      case 'VERIFICATION':
        return <ContactVerification wallet={wallet} next={next} />;
      case 'BANK':
        return <BankVerification next={next} />;
      case 'VIEWWALLET':
        return <ViewWallet next={next} />;
      case 'FINAL':
        return <Final next={next} openChat={openChat} />;
      default:
        return null;
    }
  }
  function handleCloseTransaction() {
    clearTransaction(tDispatch);
    go(0);
    setShowCancelAlert(false);
  }
  function getPcrypto() {
    if (index === 0 && qCrypto) {
      return {
        code: qCrypto,
        name: `${qCrypto.toUpperCase()}NGN`,
        flag: defaultCryptoImage[qCrypto],
      };
    }
    if (currencies && currencies.c) return currencies.c;
    return undefined;
  }

const isCalcEmbeded = () => index >= 1;

function Divider(){
  return <div
        className={cx(
          styles.rate_btn,
          isCalcEmbeded() && step !== 'CONFIRM' && styles.embed,
        )}
      ></div>
}
const lastView = () =>['VIEWWALLET','FINAL'].includes(step) ? styles.moreup:"";

  return (
    <div className={styles.app}>
      <div className={styles.header_title}>
        <a href="/"><Logo /></a>
        <p>Buy and sell crypto in minutes</p>
      </div>

      <ShowOrderStatus />
      <Divider />
      <div
        className={cx(
          styles.inner,
          isCalcEmbeded() && step !== 'CONFIRM' && styles.fullwidth,
          toggleTrans && styles.up,
          step === "VIEWWALLET" && styles.moreup
        )}
      >
        <RateBtn
          selectClass={cx(
            styles['btn-ghost'],
            styles['btn-round'],
            styles['rate'],
            styles.select,
          )}
        />

        {/* when we leave the calculator step, show navigations */}
        {isCalcEmbeded() && (
          <nav className={styles.navigation}>
            <span onClick={previous}>
              <i className='fa fa-chevron-left'></i> back
            </span>

            <span onClick={() => setShowCancelAlert(true)}>
              <i className='fa fa-times'></i> cancel
            </span>
          </nav>
        )}

        <div className={styles.viewport}>
          <div className={styles.swapView}>
            {['CONFIRM', 'VIEWWALLET', 'FINAL'].includes(step) ? (
              (() => {
                if (!cart || _.isEmpty(cart)) {
                  go(0);
                }

                return (
                  <TreviewCard
                    next={next}
                    hideAction={['VIEWWALLET', 'FINAL'].includes(step)}
                    callbackUrl={callbackUrl}
                    embeded = {Boolean(lastView())}
                    openChat={openChat}
                  />
                );
              })()
            ) : (
              <>
                <SwapCalculator
                  embeded
                  onSubmit={handleSwapEvt}
                  estimatedFee={estimatedFee || fiatAmt}
                  quantity={quantity || q_quantity}
                  P_locked={Qlocked}
                  onChange={(obj) => {
                    changeTransactionType(tDispatch, obj.tType);
                    handleSwapEvt(obj, true);
                  }}
                  hideAction={isCalcEmbeded()}
                  tType={
                    index === 0 && schema[Ttype.toLowerCase()] ? Ttype : type
                  }
                  P_crypto={getPcrypto()}
                  hideChat={hideChat}
                  showMobileSummary
                />

                {isCalcEmbeded() && step !== 'CONFIRM' && (
                  <img
                    src={LogoBlue}
                    alt='my image'
                    className={styles.footer_logo}
                  />
                )}
              </>
            )}
          </div>

          {isCalcEmbeded() && step !== 'CONFIRM' && (
            <div
              className={cx(styles.transaction_view, toggleTrans && styles.up,lastView())}
            >
              <div className={styles.mobileToggler}>
                <span
                  className={cx(styles.toggler, toggleTrans && styles.up)}
                  onClick={handleToggle}
                >
                  <i className='fa fa-angle-down'></i>
                </span>
              </div>

              {ShowCurrentStep()}
            </div>
          )}
        </div>
      </div>

      {showCancelAlert && (
        <Alert
          type='confirm'
          icon='warning'
          message='ARE YOU SURE YOU WANT TO CANCEL THIS TRANSACTION?'
          yes={handleCloseTransaction}
          no={() => setShowCancelAlert(false)}
        />
      )}
    </div>
  );
}

FullSwap.defaultProps ={
  openChat:()=>{}
};

FullSwap.propTypes = {
  hideChat: PropTypes.bool,
  callbackUrl: PropTypes.string,
};

export default withProvider(TransactionProvider)(FullSwap);