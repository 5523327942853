import React, { useState, useEffect, Fragment } from 'react';
import { DropDown } from './components';
import useRateConverter from './useRateConverter';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import LogoBlue from 'logo_blue.png';
import { ReactComponent as Locked } from './assets/svg/locked.svg';
import { ReactComponent as Unlocked } from './assets/svg/unlocked.svg';

const defaultCryptoImage = {
  ETH: 'https://s3.eu-central-1.amazonaws.com/assets.afriswap.io/ethereum.png',
  USDT:
    'https://s3.eu-central-1.amazonaws.com/assets.afriswap.io/Tether-logo.png',
  BTC: 'https://s3.eu-central-1.amazonaws.com/assets.afriswap.io/bitcoin.png',
};

function SwapCalculator({
  quantity,
  estimatedFee,
  onChange,
  P_locked,
  onSubmit,
  embeded,
  hideAction,
  no_shadow,
  swapStyles,
  activeTabStyle,
  P_crypto,
  tType,
  show_logo,
  hideChat,
  showMobileSummary,
}) {
  //check if estimatedFee or qunatity  was passed inorder to show initial Error
  const [touched, setTouched] = useState(
    Boolean(quantity) || Boolean(estimatedFee) || false,
  );

  const [type, setTransactionType] = useState(tType);

  const [currency] = useState({
    code: 'NGN',
    name: 'Nigerian naira',
    symbol: '₦',
    flag: 'https://restcountries.eu/data/nga.svg',
  });
  const [fiatTooltips,setFiatToolTips] = useState(false);
  const [cryptoTooltips,setCryptoToolTips] = useState(false);

  const getDefaultLock = ()=>{
    if(P_locked && typeof(P_locked) === "string")return P_locked;
    if(type.toUpperCase() === "SELL"){
      return "crypto"
    }  
    return "fiat"
  }

  const {
    getInputProps,
    rates,
    setBase,
    error,
    values,
    setLock,
    locked,
    fee,
    setTransType,
  } = useRateConverter({
    defaultOption: {
      crypto: P_crypto ? P_crypto.code : 'BTC',
      fiat: 'NGN',
    },
    defaultVal: {
      crypto: quantity,
      fiat: estimatedFee,
    },
    defaultLock:getDefaultLock()
  });

  const [crypto, setCrypto] = useState(
    P_crypto || {
      code: 'BTC',
      name: 'BTCNGN',
      flag: defaultCryptoImage['BTC'],
    },
  );

  useEffect(() => {
    setTransType(type);
    onChange({ values, rates, currency, crypto, tType: type, fee, locked });
    //eslint-disable-next-line
  }, [type, crypto, locked, rates]);
  
  useEffect(()=>{
    let timerID;
   if(fiatTooltips){
     timerID = setTimeout(()=>setFiatToolTips(false),2000);
   }
   return ()=>clearTimeout(timerID);
  },[fiatTooltips])

  useEffect(()=>{
   let timerID;
   if(cryptoTooltips){
     timerID = setTimeout(()=>setCryptoToolTips(false),2000);
   }
   return ()=>clearTimeout(timerID);
  },[cryptoTooltips])

  useEffect(()=>{
     if(locked === "crypto") {
       setFiatToolTips(true)
       if(cryptoTooltips)setCryptoToolTips(false);
     };

     if(locked === "fiat"){
      setCryptoToolTips(true);
      if(fiatTooltips)setFiatToolTips(false);
     }
  },[type,locked]);

  function convertsRateToDropDownFormat(rates) {
    if (!rates || !(rates instanceof Array)) {
      //throw error
      return [];
    }
    return rates.map((rate) => {
      const {
        name,
        crypto,
        tradingPairPrice: { price },
        cryptoLogoUrl,
      } = rate;
      return { name, code: crypto, flag: cryptoLogoUrl, price };
    });
  }

  function getDefaultPrice() {
    const dr = rates.find(
      (rate) => rate.crypto.toLowerCase() === crypto.code.toLowerCase(),
    );
    return dr ? dr.tradingPairPrice.price : 0.0;
  }

  /**
   * Validates form Entries
   */
  function validEntry() {
    if (!values) return false;

    if (!values.crypto || !values.fiat) {
      return false;
    }

    if (Boolean(error.fiat) || Boolean(error.crypto)) {
      return false;
    }

    return true;
  }

  //SUBMITS FORM ENTRIES
  function handleConfirm() {
    if (!validEntry()) return;
    onSubmit({ values, rates, currency, crypto, tType: type, fee, locked });
  }

  /* Used to generate message shown on input box
   * depeneding on the transaction Type
   */
  function getTransMessage(type, currency) {
    type = type.toLowerCase();

    const Mess = [
      { crypto: 'You get', fiat: 'You pay' },
      { crypto: 'You wish to sell', fiat: 'You will be paid' },
    ];

    if (type == 'buy') return Mess[0][currency] || '';
    if (type == 'sell') return Mess[1][currency] || '';

    return '';
  }

  // ALLOWS USER CUSTOMIZATION OF THE TAB BUTTONS
  const addTabstyle = (tab) =>
    type === tab && typeof activeTabStyle === 'object'
      ? activeTabStyle
      : undefined;

  // ALLOWS USER CUSTOMIZATION OF THE CONTAINER ELEMENT
  function addSwapStyle() {
    return typeof swapStyles === 'object' ? swapStyles : undefined;
  }

  /**
   * helps to generate a classname for swap inputs
   * depending on the lengths of their value,
   * i.e shrinks them for every multiples of 3 greater than 6
   * and stops shrinking at 9
   * @param {String | Number} value
   */
  function generateFontClassName(value) {
    return cx(
      String(value).length >= 6 && styles.small2,
      String(value).length >= 9 && styles.small3,
    );
  }

  // onClick handlers for Lock icon, to toggle Lock state
  function toggleLock() {
    if (locked === 'crypto') {
      setLock('fiat');
    } else if (locked === 'fiat') {
      setLock('crypto');
    }
  }

  const showLockStatus = (expect) =>
    locked === expect ? <Locked /> : <Unlocked />;
  const Header = () => (hideAction ? <MobileSummary /> : <Tabs />);

  return (
    <div
      className={cx(
        styles.swap_calculator,
        embeded && styles.no_shadow,
        hideAction && styles.embeded,
        no_shadow && styles.no_shadow,
      )}
      style={addSwapStyle()}
    >
      <Header />

      <div
        className={cx(
          styles.transaction_controls,
          type === 'SELL' && styles.reverse,
        )}
      >
        <div
          className={cx(
            styles.swap_input,
            error['fiat'] && touched && styles['input-error'],
          )}
        >
          {error.fiat && touched && (
            <label className={styles.error}>{error.fiat}</label>
          )}
          <small>{getTransMessage(type, 'fiat')}</small>
          <span
            onClick={() => {
              if (!hideAction) toggleLock(); 
            }}
            className={styles.lockings}
          >
            {showLockStatus('fiat')}
            <ToolTip show={fiatTooltips} pair={"fiat"} />
          </span>

          <input
            type='text'
            {...getInputProps('fiat')}
            disabled={hideAction || !rates.length}
            className={generateFontClassName(values.fiat)}
            onFocus={() => setTouched(true)}
          />
          <div className={styles.dropdown}>
            <DropDown data={[]} value={currency} disabled={hideAction} />
          </div>
        </div>

        <div
          className={cx(
            styles.swap_input,
            error['crypto'] && touched && styles['input-error'],
          )}
        >
          {error.crypto && touched && (
            <label className={styles.error}>{error.crypto}</label>
          )}

          <small>{getTransMessage(type, 'crypto')}</small>

          <span
            onClick={() => {
              if(!hideAction) toggleLock();
            }}
            className={styles.lockings}
          >
            {showLockStatus('crypto')}
            <ToolTip show={cryptoTooltips} pair={"crypto"} />
          </span>

          <input
            type='text'
            {...getInputProps('crypto')}
            disabled={hideAction || !rates.length}
            className={generateFontClassName(values.crypto)}
            onFocus={() => setTouched(true)}
          />

          <div className={styles.dropdown}>
            <DropDown
              data={convertsRateToDropDownFormat(rates)}
              value={crypto}
              disabled={hideAction}
              onChange={(value) => {
                setCrypto(value);
                setBase('crypto', value.code);
              }}
            />
          </div>
        </div>
      </div>

      <div className={styles.transaction_summary}>
        <div className={styles.flexed}>
          <p>
            <span className={styles.tcoin}>{`1 ${crypto.code}`}</span>
          </p>

          <p>
            @ ₦&nbsp;
            {BigNumber(getDefaultPrice()).toFormat(2)}
          </p>
        </div>
        <div className={styles.flexed} style={{minHeight:19}}>
          {type === 'BUY' && (
            <>
              <span> Fee as low as </span>
              <span>
                {BigNumber(fee.feeInCrypto).toFixed(8)}
                {crypto.code}
              </span>
            </>
          )}
        </div>
        <div className={styles.total}>
          <div className={styles.flexed}>
            {/* <p>Total</p>
            <p>₦&nbsp;{BigNumber(values.fiat).toFormat(2)}</p> */}
          </div>
        </div>
      </div>

      <div className={styles.action}>
        {!hideAction && (
          <button
            className={cx(
              styles.btn_action,
              styles.swap_btn,
              validEntry() ? styles['btn-primary'] : styles['btn-disabled'],
            )}
            onClick={handleConfirm}
          >
            {type} NOW
          </button>
        )}
      </div>

      {!hideChat && (
        <div className={styles.chat}>
          <span>
            <i className='fa fa-comments-o'></i>
            <span className={styles.chat__action}>chat with us</span>
          </span>
        </div>
      )}
    </div>
  );

  function Logo() {
    return show_logo ? (
      <div className={styles.logo_blue}>
        <img
          src={LogoBlue}
          alt='afriswap logo'
          style={{ width: 106, height: 25 }}
        />
      </div>
    ) : null;
  }

  function Tabs() {
    return (
      <>
        <Logo />
        <div className={styles.tabs}>
          <div
            className={cx(
              styles.tabs__item,
              type === 'BUY' && cx(styles.active),
            )}
            onClick={() => setTransactionType('BUY')}
            style={addTabstyle('BUY')}
          >
            BUY
          </div>
          <div
            className={cx(styles.tabs__item, type === 'SELL' && styles.active)}
            onClick={() => setTransactionType('SELL')}
            style={addTabstyle('SELL')}
          >
            SELL
          </div>
        </div>
      </>
    );
  }

  function MobileSummary() {
    return (
      <>
        <h4
          className={cx(styles.title, showMobileSummary && styles.hideOnMobile)}
        >
          YOUR {type} ORDER
        </h4>

        <div
          className={cx(
            styles.mobileSummary,
            showMobileSummary && styles.showOnMobile,
          )}
        >
          <h3> i want to {type}</h3>

          <p>
            {BigNumber(values.crypto).toFormat(7)}
            {crypto.code} <span>for</span> {BigNumber(values.fiat).toFormat(2)}
            {currency.code}
          </p>
        </div>
      </>
    );
  }
  function ToolTip({show, pair}){
    let message = "";
    if(pair === "crypto")message = "fee will be subtracted from coin";
    if(pair === "fiat")message = "fee will be added to fiat amount";
     return show ? (
       <div className={styles.tooltip}>
             {message}
       </div>
     ): null;
  }
}

SwapCalculator.propTypes = {
  quantity: PropTypes.number,
  estimatedFee: PropTypes.number,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

SwapCalculator.defaultProps = {
  quantity: 0,
  estimatedFee: 0,
  onChange: () => {},
  onSubmit: () => {},
  tType: 'BUY',
  show_logo: true,
};
export default SwapCalculator;
