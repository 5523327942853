import React, { useRef, useEffect } from 'react';
import OverLay from '../OverLay/OverLay';
import styles from './TransactionState.module.scss';
import { ReactComponent as Error } from '../../assets/img/error.svg';
import { ReactComponent as Pend } from '../../assets/img/pending.svg';
import { ReactComponent as Success } from '../../assets/img/success.svg';
import { saveToPdf } from '../../utils';
const config = {
  error: {
    icon: <Error />,
    status: "OOPS! WE'RE SORRY",
    message: 'Your transaction was not successful',
    btn: 'print',
    btn2: 'start a new transaction',
  },

  pending: {
    icon: <Pend />,
    status: 'PENDING',
    message: 'Your payment is still pending',
    btn: 'ok',
  },

  complete: {
    icon: <Success />,
    status: 'THANK YOU!',
    message: 'Your transaction was successful',
    btn: 'print',
    btn2: 'close',
  }
};

const joinClasses = (...list) => {
  const style = [];

  list.forEach((s) => style.push(styles[s]));

  return style.join(' ');
};

const formatDateTime = (date, asTime = false) => {
  date = date ? new Date(date) : new Date();

  let formatter;

  if (!asTime) {
    formatter = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return formatter.format(date).replace(',', '');
  } else {
    formatter = new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    const dateString = formatter
      .formatToParts(date)
      .map(({ type, value }) => {
        switch (type) {
          case 'hour':
            return value.padStart(2, '0');
          case 'dayPeriod':
            return value.toUpperCase();
          default:
            return value;
        }
      })
      .join('');

    return dateString;
  }
};

function Actions({ state, onClick1, onClick2 }) {
  if (state === 'pending') {
    <div className={joinClasses('actions', 'pending')}>
      <button className={styles.button} onClick={onClick1}>
        {config[state].btn}
      </button>
    </div>;
  }

  return (
    <div className={styles.actions}>
      <button id="print" className={styles.button} onClick={onClick1}>
        {config[state].btn}
      </button>

      <button id="close" className={joinClasses('button', 'second')} onClick={onClick2}>
        {config[state].btn2}
      </button>
    </div>
  );
}

function TransactionState(props) {
  let {
    status: state,
    transactionType,
    createdAt,
    cryptoAmount,
    fiatAmount,
    amountPaid,
    wallet = {},
    orderId,
  } = props;

  state = state && state.toLowerCase();
  state = state || 'complete';
  //incase  user refreshes more than once
  if (state.startsWith('blockchain')) {
    state = 'complete';
  }

  const Icon = config[state.toLowerCase()] && config[state.toLowerCase()].icon;
  const orderRef = useRef(null);
  const wrapperRef = useRef(null);
  const generateFileName = () =>
    `order_${orderId}_${formatDateTime(createdAt * 1000).replace(/\s/g, '_')}`;

  function addClonedToDom(){
       if(wrapperRef.current && orderRef.current){
           const cloned = orderRef.current.cloneNode(true);
           cloned.querySelector("#print").remove();
           cloned.querySelector("#close").remove();
           cloned.style.transform="translate(0,-9999999999999px)";
           cloned.style.position="absolute";
           wrapperRef.current.appendChild(cloned);
           return cloned;
       }
       return null;
  }

  function removeClonedFromDom(cloned){
    if(wrapperRef.current && orderRef.current){
       wrapperRef.current.removeChild(cloned);
    }
  }

  return (
    <OverLay>
      <div ref={wrapperRef}>
      <div className={styles.modal} ref={orderRef}>
        <section className={styles.header}>
          <span className={`${styles.icon} ${styles[state]}`}>
            {Icon && Icon}
          </span>

          <h3 className={joinClasses('bolded', 'status', 'heading')}>
            {config[state].status}
          </h3>

          <p> {config[state].message} </p>
        </section>

        <p className={joinClasses('summary', 'bolded')}>
          You bought {cryptoAmount} {wallet.currency} at {wallet.currency}/NGN N
          {fiatAmount}
        </p>
        <section>
          <div className={styles.flexed}>
            <p className={styles.left}>Transaction ID</p>
            <p className={styles.right}>{orderId}</p>
          </div>
        </section>

        <section className={styles.dateTime}>
          <div className={styles.flexed}>
            <p className={styles.left}>Date</p>
            <p className={styles.right}>{formatDateTime(createdAt * 1000)}</p>
          </div>

          <div className={styles.flexed}>
            <p className={styles.left}>Time</p>
            <p className={styles.right}>{formatDateTime(createdAt * 1000, true)}</p>
          </div>
        </section>

        <section className={styles.fee}>
          <div className={styles.flexed}>
            <p className={styles.left}>Amount</p>
            <p className={styles.right}>
              {cryptoAmount} {wallet.currency}= {fiatAmount}
            </p>
          </div>

          <div className={styles.flexed}>
            <p className={styles.left}>Transaction Fee</p>
            <p className={styles.right}>0.000 BTC = N000.0</p>
          </div>
        </section>

        <div className={joinClasses('flexed', 'bolded', 'total')}>
          <p className={styles.left}>Total</p>
          <p className={styles.right}>N{amountPaid}</p>
        </div>

        <Actions
          state={state}
          onClick1={() => {
            saveToPdf(addClonedToDom(), generateFileName(),removeClonedFromDom);
          }}
          onClick2={props.onClick2}
        />
      </div>
      </div>
    </OverLay>
  );
}

export default TransactionState;
