import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./Rdpd.module.scss";
import cx from "classnames";
export function RDropDown({
  value,
  name,
  data,
  onChange,
  block,
  title,
  type,
  ghost,
  scroll,
  isInvalid,
  placeholder,
  defaultMessage,
}) {
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);
  const testref = useRef(null);
  const toggleDropDown = () => {
    setIsDropDownOpened(!isDropDownOpened);
  };
  const handleOnClickOutside = useCallback(
    (ev) => {
      ev.stopPropagation();
      if (!ev.target.matches(styles.dropdownWrapper)) {
        if (isDropDownOpened) setIsDropDownOpened(false);
      }
    },
    [isDropDownOpened]
  );
  useEffect(() => {
    window.addEventListener("click", handleOnClickOutside);
    return () => {
      window.removeEventListener("click", handleOnClickOutside);
    };
  }, [handleOnClickOutside]);
  const types = {
    medium: styles.md, //for smaller width
    large: styles.full, //for larger width style
  };
  const defaultType = types["medium"];
  let currentLetter = "";
  function handleOnKeyPress(ev) {
    const alpha = "abcdefghijklmnopqrstuvwxyz".split("");
    if(!alpha.includes(ev.key))return;
    const picked = testref.current.querySelector(`#${ev.key.toUpperCase()}`);
    if (picked) {
      testref.current.scrollTo(0, picked.offsetTop);
    }
  }
  return (
    <div
      className={cx(
        styles.dropdownWrapper,
        block ? styles.block : "",
        types[type] || defaultType
      )}
    >
      {title && <label>{title}</label>}
      <div
        className={cx(
          styles["custom-dropdown"],
          ghost && styles.ghost,
          isInvalid && styles.invalid
        )}
        onClick={toggleDropDown}
        onKeyPress={handleOnKeyPress}
        tabIndex={0}
      >
        {isDropDownOpened && (
          <ul
            className={cx(styles.dropDownOption, styles.full, scroll && styles.scroll)}
            ref={testref}
          >
            {data &&
              (!data.length ? (
                <DropDownItem onChange={toggleDropDown}>
                  {defaultMessage}
                </DropDownItem>
              ) : (
                data.map((item, index) => {
                    const { label="" } = item;
             return (
                  <DropDownItem
                    key={`Menu ${index}`}
                    id={(() => {
                      if (label[0] !== currentLetter) {
                        currentLetter = label[0];
                        return label[0];
                      }
                      return undefined;
                    })()}
                    onClick={
                      onChange
                        ? () => {
                            onChange(item);
                            toggleDropDown();
                          }
                        : void 0
                    }
                  >
                    {label}
                  </DropDownItem>
                )})
              ))}
          </ul>
        )}
        <p>
          {value || placeholder}{" "}
          <span className={styles.caret}>
            <i className="fa fa-chevron-down"></i>
          </span>
        </p>
      </div>
    </div>
  );
}

function DropDownItem({ onClick, children, id }) {
  return (
    <li id={id && id} onClick={onClick}>
      {children}
    </li>
  );
}
RDropDown.defaultProps = {
  isInvalid: false,
  placeholder: "--select--",
};
RDropDown.propTypes = {
  value: PropTypes.string, //to dispaly value
  data: PropTypes.array, //to options to pick from
  onChange: PropTypes.func, //to handler for selection
  title: PropTypes.string, //to show a label at the top
  block: PropTypes.bool, //to overide the inline-block flow
  ghost: PropTypes.bool, //for transparent background
  scroll: PropTypes.bool, //to prevent the menu from growing when data is large
  type: PropTypes.string, //switch betwwen large or small size, this also affects the dropdown menu
  isInvalid: PropTypes.bool, // for ux experience
  defaultMessage: PropTypes.string, //messgae to displayed when there is no data
};
