import React from 'react';
import styles from "./OverLay.module.scss";

function OverLay ( { children } ) 
{
    return (
        <div className={ styles.overlay }>
            {children }
        </div>
    );
}

export default OverLay;
