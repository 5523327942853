import React, { Fragment, useState } from 'react';
import { contactSchema } from './ValidationSchema';
import { VerificationView } from './VerificationView';
import { useFormik } from 'formik';
import cx from 'classnames';
import IntlTelInput from 'react-intl-tel-input';
import Oldstyle from '../../styles.module.scss';
import styles from './scss/styles.module.scss';
import 'react-intl-tel-input/dist/main.css';
import { useUnload } from '../Hooks';
import {
  useTransactionState,
  useTransactionDispatch,
  createTransaction,
  createVerify,
} from '../../factory';
import { removeProperty } from '../../utils';
//import PropTypes from 'prop-types';

function ContactVerifiCation({ wallet, next }) {

  const {
    transaction,
    currencies,
    loading,
    transactionType,
    cart,
  } = useTransactionState();

  useUnload((e) => {
    e.preventDefault();
    const message = 'your changes might be lost';
    e.returnValue = message;
    return message;
  });

  const tDispatch = useTransactionDispatch();
  const [isInverify, setIsInVerify] = useState(false);
  const [sentTo, setSentTo] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const initials = {
    wallet: {
      address: wallet,
      currency: currencies.c && currencies.c.code,
    },
    customer: {
      option: 'email',
      email: '',
      phoneNumber: '',
    },
    acceptedTermsAndCondition: false,
    isSubscribed: false,
    transactionType,
  };

  const {
    errors,
    touched,
    values,
    getFieldProps,
    handleSubmit,
    isValid,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: initials,
    initialErrors: removeProperty('transactionType'),
    validationSchema: contactSchema,

    onSubmit: (values) => {
      if (loading) return; //to avoid multiple request;
    const { isSubscribed, acceptedTermsAndCondition, ...rest } = values;

    function transFormData(type) {
        if (type == 'SELL') {
          const { wallet, ...sellData} = rest;
          const {
            quantity: cryptoToSell,
            estimatedRate,
            ...tRest
          } = transaction;
          return { ...sellData, ...tRest, cryptoToSell };
        }
        return { ...rest, ...transaction };
    }

      createTransaction(
        tDispatch,
        transFormData(transactionType),
        transactionType,
      ).then((data) => {
        //create verify
        createVerify(tDispatch, {
          [values.customer.option]: values.customer[values.customer.option],
          transactionRef: data.transactionRef,
          type: transactionType,
          isSubscribed,
          acceptedTermsAndCondition
        }).then(() => {
          setIsInVerify(true);
          setSentTo(values.customer[values.customer.option]);
        });
      });
    },
  });

  const checkError = (field) =>
    errors.customer &&
    errors.customer[field] &&
    touched.customer &&
    touched.customer[field];

  return (
    <div className={styles.verify}>
      <h3 className={`${styles.title} ${styles.mini}`}>
        LET'S GET YOU NOTIFICATION READY!
      </h3>

      <form onSubmit={handleSubmit}>
        <div className={styles.control_region}>
          {values.customer.option == 'phoneNumber' && (
            <div className={styles.form_control}>
              <label
                className={cx(
                  errors.customer &&
                    errors.customer.phoneNumber &&
                    styles.error,
                )}
              >
                Enter your phoneNumber
              </label>
              <IntlTelInput
                containerClassName={`intl-tel-input ${styles.form_control} ${styles.react_int_tel_input_overwrite}`}
                inputClassName={cx(
                  errors.customer &&
                    errors.customer.phoneNumber &&
                    styles.input_error,
                )}
                autoFocus
                formatOnInit
                format
                separateDialCode
                defaultCountry='ng'
                value={phoneNumber}
                onlyCountries={['ng']}
                preferredCountries={['ng']}
                onPhoneNumberChange={(isValid, value, country) => {
                  //clear space and check if value exceed max lenght
                  let formatedVal = value.replace(/ /g, '');
                  if (formatedVal.length > 11) return;
                  if (isValid) {
                    if (value.startsWith('0')) {
                      value = value.slice(1);
                    }
                    setFieldValue(
                      'customer.phoneNumber',
                      `+${country.dialCode}${value.replace(/ /g, '')}`,
                    );
                  }

                  if (!isValid) {
                    setFieldError('customer.phoneNumber', 'invalid');
                  }
                  setPhoneNumber(value);
                }}
              />

              <label
                onClick={() => {
                  setFieldValue('customer.option', 'email');
                  isInverify && setIsInVerify(false);
                }}
              >
                <u>Or email address</u>
              </label>
            </div>
          )}

          {values.customer.option == 'email' && (
            <>
              <div className={styles.form_control}>
                <label className={cx(checkError('email') && styles.error)}>
                  Enter your email address
                </label>

                <input
                  type='email'
                  name='customer.email'
                  {...getFieldProps('customer.email')}
                  className={cx(checkError('email') && styles.input_error)}
                />

                <label
                  onClick={() => {
                    setFieldValue('customer.option', 'phoneNumber');
                    isInverify && setIsInVerify(false);
                  }}
                >
                  <u>Or phone number</u>
                </label>
              </div>
            </>
          )}

          <div className={styles.infoarena} style={{ minHeight: 108 }}>
            {/*after making verification request, 
          show the verification complete ui, 
          where users can input their otp */}
            {isInverify ? (
              <VerificationView
                loading={loading}
                type={transactionType}
                customer={values}
                transactionRef={cart && cart.transactionRef}
                next={next}
                reset={() => setIsInVerify(false)}
                sentTo={sentTo}
              />
            ) : (
              <div style={{ marginTop: 35 }}>
                <div className={styles.checkbox_control}>
                  <input
                    type='checkbox'
                    name='acceptedTermsAndCondition'
                    {...getFieldProps('acceptedTermsAndCondition')}
                    className={
                      errors.acceptedTermsAndCondition &&
                      touched.acceptedTermsAndCondition
                        ? styles.error
                        : undefined
                    }
                    checked={values.acceptedTermsAndCondition}
                  />
                  <label>
                    I agree to Afriswap's{' '}
                    <a href='https://test.afriswap.io/terms' target='_blank'>
                      Terms of Service
                    </a>
                  </label>
                </div>
                {values.customer.option === 'email' && (
                  <div className={styles.checkbox_control}>
                    <input
                      type='checkbox'
                      name='isSubscribed'
                      {...getFieldProps('isSubscribed')}
                      value={values.isSubscribed}
                    />
                    <label>
                      You can occasionally email me updates about Afriswap{' '}
                      <span style={{ color: '#04A339' }}>OPTIONAL</span>
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {/* hide formik button when view isInverify  */}
        {!isInverify && (
          <button
            type='submit'
            disabled={loading}
            className={cx(
              Oldstyle.btn_action,
              !isValid && Oldstyle['btn-disabled'],
            )}
          >
            {loading ? (
              <i className='fa fa-spinner fa-spin'></i>
            ) : (
              'GET VERIFICATION CODE'
            )}
          </button>
        )}
      </form>
    </div>
  );
}

ContactVerifiCation.propTypes = {};

export default ContactVerifiCation;
