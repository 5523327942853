import html2Canvas from "html2canvas";
import jsPDF from "jspdf";


export function setNativeValue(element, value) {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    'value',
  ).set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else {
    valueSetter.call(element, value);
  }
}

export function fetchRates(transtype = 'buy') {
  transtype = transtype.toLowerCase();
  if (typeof transtype === 'undefined')
    console.error('invalid transaction type in rate converter');
  // const requestOptions = {
  //   accept: '*/*',
  // };
  return fetch(
    `https://api.dev.afriswap.io/api/rates/exchange/${transtype}`,
    // requestOptions,
  ).then(handleResponse);
}
export function getLiveFees(){
  return fetch(
    `https://api.dev.afriswap.io/api/rates/fees`,
     {accept:"*/*"},
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

export function checkedJsonStorage(itemName) {
  let myItem = null;
  try {
    myItem = JSON.parse(localStorage.getItem(itemName));
  } catch (err) {
    //console.log(err);
  }
  return myItem;
}

export function saveToPdf(domElem,fileName,onFinish = ()=>{}){
  if(!domElem)return;
  html2Canvas(domElem)
  .then(canvas =>{
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF({
          orientation: 'portrait',
    });
    const imgProps= pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(fileName || "order_new.pdf");
    if(typeof onFinish == "function")onFinish(domElem);
  })
}

export const removeProperty = prop => ({ [prop]: _, ...rest }) => rest