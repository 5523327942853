import React, { useState, useRef, useEffect, Fragment } from 'react';
import OtpInput from 'react-otp-input';
import { useTransactionDispatch, completeVerify } from '../../factory';
import FormState from './FormState';
import cx from 'classnames';
import styles from './scss/styles.module.scss';
import Oldstyle from '../../styles.module.scss';

export function VerificationView({
  sentTo,
  transactionRef,
  type,
  loading,
  error,
  customer,
  next,
  reset,
})
{
  const tdispatch = useTransactionDispatch();
  const [value, setValue] = useState('');
  const [verified, setIsVerified] = useState(false);
  const [verifyError, setVerifyError] = useState('');

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    let timerId = setInterval(() => {
      if (seconds === 0 && minutes === 0) {
        clearInterval(timerId);
        setTimedOut(true);
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0 && minutes > 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
        return;
      }
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  });

  function formatedTime() {
    let sec = seconds < 10 ? `0${seconds}` : seconds;
    let min = minutes < 10 ? `0${minutes}` : minutes;
    if (timedOut) return <span>otp expired</span>;
    return (
      <>
        Your OTP expires in <span className={styles.time}>{min}</span> minutes :{' '}
        <span className={styles.time}> {sec}</span> sec{' '}
      </>
    );
  }

  function verify(e) {
    e.preventDefault();

    if (verified) {
      next();
      return;
    }

    completeVerify(tdispatch, { otp: value, transactionRef, type }, customer)
      .then((usr) => {
        setIsVerified(true);
      })
      .catch((message) => {
        setVerifyError(message);
      });
  }

  function showElement() {
    if (verifyError) {
      return (
        <div className={styles.vloader} style={{ minHeight: 125 }}>
          <FormState type='error' message={verifyError} />
        </div>
      );
    }

    if (loading) {
      return (
        <div className={styles.vloader} style={{ minHeight: 125 }}>
          <FormState type='loading' message='verifying' />
        </div>
      );
    }

    if (verified) {
      return (
        <div className={styles.vloader} style={{ minHeight: 125 }}>
          <FormState
            type='okay'
            message={`${
              customer.customer.option === 'phoneNumber'
                ? 'Phone number'
                : customer.customer.option
            } has been verified`}
          />
        </div>
      );
    }

    return (
      <>
        <FormState
          type='preli'
          message={`Please enter the verification code sent to ${sentTo} below`}
          noIcon
        />

        <OtpInput
          value={value}
          onChange={setValue}
          numInputs={6}
          containerStyle={styles.otp__box}
        />
        {value.length !== 6 && <p className={styles.timer}>{formatedTime()}</p>}
      </>
    );
  }

  function Action() {
    if (verifyError) {
      return (
        <button
          style={{ marginBottom: 0 }}
          className={cx(Oldstyle.btn_action)}
          onClick={(e) => {
            e.preventDefault();
            setVerifyError('');
            setValue(' ');
          }}
        >
          Okay
        </button>
      );
    }

    if (value.length === 6) {
      return (
        <button
          disabled={loading}
          className={cx(Oldstyle.btn_action, styles.cbtn_ver)}
          onClick={verify}
        >
          {loading ? (
            <i className='fa fa-spinner fa-spin'></i>
          ) : verified ? (
            'PROCEED'
          ) : (
            'VERIFY'
          )}
        </button>
      );
    }

    return null;
  }

  return (
    <div className={styles.otp}>
      {showElement()}
      <Action />
      {!verifyError && (
        <span className={styles.reset} style={{ textAlign: 'center' }}>
          {(timedOut || !verified) && (
            <u onClick={reset}>Kindly click here to resend</u>
          )}
        </span>
      )}
    </div>
  );
}

VerificationView.defaultProps = {
  type: '',
};
