import CONFIG from "../../../main.config";
 
export const TRANSACTION_REQUEST = "TRANSACTION_REQUEST",
  TRANSACTION_REQUEST_SUCCESS = "TRANSACTION_REQUEST_SUCCESS",
  TRANSACTION_ERROR = "TRANSACTION_ERROR",
  UPDATE_TRANSACTION = "UPDATE_TRANSACTION",
  CLEAR_ERROR = "CLEAR_ERROR",
  TOGGLE_USECART = "TOGGLE_USECART",
  CHANGE_TRANSACTION_TYPE = "CHANGE_TRANSACTION_TYPE",
  CREATE_CUSTOMER = "CREATE_CUSTOMER",
  VERIFY_CUSTOMER = "VERIFY_CUSTOMER",
  CART_SUCCESS = "CART_SUCCESS",
  ORDER_VERIFY_SUCCESS = "ORDER_VERIFY_SUCCESS",
  CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS",
  SITE_URL = CONFIG.REACT_APP_FRONTEND_URL,
  BASE_SELL_API = `${CONFIG.REACT_APP_API_URL}/cart/sell`,
  BASE_BUY_API = `${CONFIG.REACT_APP_API_URL}/cart/buy`,
  BASE_VERIFY_API = `${CONFIG.REACT_APP_API_URL}/cart/verify`;
 