import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import MenuList from './MenuList';
import styles from './DropDown.module.scss';

export function DropDown({ data, value, onChange, filter, disabled }) {
  const [isDropDownOpened, setIsDropDownOpened] = useState(false);
  const toggleDropDown = () => {
    setIsDropDownOpened(!isDropDownOpened);
  };
  const testref = useRef(null);
  let currentLetter = useRef('');
  //scroll to text on keypress
  function handleOnKeyPress(ev) {
    const alpha = 'abcdefghijklmnopqrstuvwxyz'.split('');
    if (!alpha.includes(ev.key)) return;
    const picked = testref.current.querySelector(`#${ev.key.toUpperCase()}`);
    if (picked) {
      testref.current.scrollTo(0, picked.offsetTop);
    }
  }
  const { flag, code, name } = value || {};
  return (
    <div className={styles.dropdown_wrapper}>
      <div
        className={styles.dropdown}
        onClick={() => toggleDropDown}
        onKeyPress={handleOnKeyPress}
        onFocus={() => setIsDropDownOpened(true)}
        onBlur={() => setIsDropDownOpened(false)}
        tabIndex={0}
      >
        <span
          onClick={() => toggleDropDown()}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          {flag && (
            <div
              className={styles.flag}
              style={{ backgroundImage: `url(${flag})` }}
            ></div>
          )}
          <span className={styles.view}>{code}</span>
          <span
            style={{
              fontSize: 17,
              marginLeft: 5,
              transform: isDropDownOpened ? 'rotate(180deg)' : '',
              marginRight:10
            }}
          >
            <i className='fa fa-caret-down'></i>
          </span>
        </span>
        {isDropDownOpened && !disabled && (
          <MenuList
            testRef={testref}
            data={data}
            onChange={(obj) => {
              if(!disabled){
                onChange(obj);
              }
              toggleDropDown();
            }}
            currentLetter={currentLetter}
          />
        )}
      </div>
    </div>
  );
}

DropDown.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};
DropDown.defaultProps = {
  onChange: void 0,
};
