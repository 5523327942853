import React, { useState, useEffect, useCallback } from 'react';
import styles from './scss/styles.module.scss';
import Oldstyle from '../../styles.module.scss';
import { RDropDown } from '../DropDown/RDropDown';
import { Formik, Form, Field } from 'formik';
//import PropTypes from 'prop-types';
import { bankSchema } from './ValidationSchema';
import Axios from 'axios';
import cx from 'classnames';
import { useUnload } from '../Hooks';
import {
  useTransactionState,
  useTransactionDispatch,
  updateSellTransaction,
} from '../../factory';
import { verifyAccounNumber } from '../../helpers';
import Alert from '../Alert/Alert';
let timer,
  timeoutVal = 1000;

function BankVerification({ next }) {
  const {
    customer,
    loading,
    transactionType,
    cart:{ transactionRef },
    transaction: { estimatedFee, tradingPair, quantity: cryptoToSell } = {},
  } = useTransactionState();

  const tdispatch = useTransactionDispatch();
  const [supportedBanks, setSupportedBanks] = useState([]);
  const [message, setMessage] = useState({ laoding: false, message: '' });
  const [success, setSuccess] = useState(false);

  const initialValues = {
    isValidAccount: false,
    accountDetails: {
      accountName: '',
      accountNumber: '',
      bankName: '',
      bankCode: '',
    },
    cryptoToSell,
    customer: customer.customer,
  };

  useEffect(() => {
    const ourRequest = Axios.CancelToken.source() 
    getBanks(ourRequest.token);
    return ()=>ourRequest.cancel();
  }, []);
 
useUnload(e=>{
  e.preventDefault();
  const message = "your changes might be lost";
  e.returnValue= message;
  return message;
})

  function Spinner() {
    if (message.loading) {
      return (
        <span className={styles.icon}>
          <i className='fa fa-spinner fa-spin'></i>
        </span>
      );
    }
    return null;
  }

  function FeedBack({isValidAccount}){
    return (
      <span className={styles.icon} style={{color:isValidAccount ? "blue":"red"}}>
        {isValidAccount ? (<i className='fa  fa-check'></i>):<i className='fa fa-times'></i>} 
      </span>
    );
  }

  return (
    <div className={styles.verify}>
    <Formik
      initialValues={initialValues}
      initialErrors={initialValues}
      validationSchema={bankSchema}
      onSubmit={(values) => {
        delete values.customer.option;
        const { isValidAccount, ...rest} = values;
        updateSellTransaction(tdispatch,transactionRef, rest ).then(()=>setSuccess(true));
      }}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
        isValid,
      }) => {
        function setFieldValueSafely(Field, value) {
          setFieldValue(Field, value, true);
          setFieldTouched(Field);
        }


        function handleBankVerification(e) {
          // prevent errant multiple timeouts from being generated
          window.clearTimeout(timer);
          //only make verify request once user stop typing
          timer = window.setTimeout(() => {
            if(!timer){
             return 
            }
            setMessage({ loading: true, message: 'verifying account' });
            verifyAccounNumber(
              values.accountDetails.accountNumber,
              values.accountDetails.bankCode,
            )
              .then((response) => {
                setFieldValue('isValidAccount', true);
                setFieldValueSafely(
                  'accountDetails.accountName',
                  response.data.account_name,
                );
                setMessage({ loading: false, message: 'valid' });
              })
              .catch((err) => {
                if (
                  err.response &&
                  (err.response.status === 409 || err.response.status === 400)
                ) {
                  setFieldValue('isValidAccount', false);
                  setMessage({ loading: false, message: 'invalid' });
                  setFieldValueSafely('accountDetails.accountName', '');
                  return;
                }
                setFieldValueSafely('accountDetails.accountName', '');

                setMessage({ loading: false, message: '' });
              });
          }, timeoutVal);
        }


        useEffect(() => {
           handleBankVerification()
         return () =>  window.clearTimeout(timer);;
        }, [values.accountDetails.bankCode])
        return (
          <Form>
            <div style={{ width: '100%' }}>
              <h3 className={styles.title}>
                LET'S GET YOUR BANK ACCOUNT DETAILS
              </h3>
              <div className={styles.form_control}>
                <RDropDown
                  data={supportedBanks}
                  value={values.accountDetails.bankName}
                  title={'Bank Name'}
                  onChange={({ value, code }) => {
                    setFieldValue('accountDetails.bankName', value);
                    setFieldValue('accountDetails.bankCode', code);
                  }}
                  placeholder='select bank'
                  isInvalid={Boolean(
                    errors.accountDetails &&
                      errors.accountDetails.bankName &&
                      touched.accountDetails &&
                      touched.accountDetails.bankName,
                  )}
                  ghost
                  type='large'
                  scroll
                />
              </div>

              <div className={`${styles.form_control} ${styles.with_icon}`}>
                <label>Bank Account Number</label>

                <Field
                  type='text'
                  name='accountDetails.accountNumber'
                  maxLength={10}
                  placeholder='0000000000'
                  disabled={!Boolean(values.accountDetails.bankCode)}
                  onKeyUp={handleBankVerification}
                  onKeyPress={() => {
                    //user is typing dont verify yet
                    window.clearTimeout(timer);
                  }}
                  className={
                    touched.accountDetails &&
                    touched.accountDetails.accountNumber &&
                    !values.isValidAccount
                      ? styles.input_error
                      : ''
                  }
                />
              <FeedBack isValidAccount={values.isValidAccount} />
              </div>

              <div className={`${styles.form_control} ${styles.with_icon}`}>
                <label>Bank Account Name</label>

                <Field
                  name='accountDetails.accountName'
                  placeholder={message.loading ? 'checking..' : 'john doe'}
                  disabled={true}
                  className={`${
                    errors.accountDetails &&
                    errors.accountDetails.accountName &&
                    touched.accountDetails &&
                    touched.accountDetails.accountName
                      ? styles.input_error
                      : ' '
                  }`}
                />

                <Spinner />
              </div>

              <button
               type="submit"
               style={{marginTop:2}} //to keep with height 546px
                className={cx(
                  Oldstyle.btn_action,
                  !isValid && Oldstyle['btn-disabled'],
                )}
              >
                {loading ? (
                  <i className='fa fa-spinner fa-spin'></i>
                ) : (
                  'CONFIRM BANK DETAILS'
                )}
              </button>
            </div>
            {success && (
              <Alert
                type='alert'
                message='YOUR BANK DETAILS HAVE BEEN SUCCESSFULLY CONFIRMED'
                okay={() => {
                  setSuccess(false);
                  next();
                }}
              />
            )}
          </Form>
        );
      }}
    </Formik>
    </div>
  );

  //////////////////////////////////////////////////
  async function getBanks(cancelToken) {
    try {
      const response = await Axios.get(
        'https://api.dev.afriswap.io/api/utils/banks/NGN',
        {
          accept: '*/*',
          cancelToken,
        },
      );
      const banks = response.data.data.map(({ name, code }) => ({
        label: name,
        value: name,
        code,
      }));
     setSupportedBanks(banks);
    } catch (err) {
      console.log('couldnt fetch listed banks', err);
    }
  }
}

BankVerification.propTypes = {};

export default BankVerification;
