import React from 'react';
import { transactionReducer } from './transaction.reducer';
import { checkedJsonStorage } from '../../utils';
import { default as Alert } from '../../components/Alert/Alert';
import { clearError }  from "./transaction.actions"

var TransactionStateContext = React.createContext();
var TransactionDispatchContext = React.createContext();

function TransactionProvider({ children }) {
  const Trx_type = checkedJsonStorage('Trx_type');
  var [state, dispatch] = React.useReducer(transactionReducer, {
    error: '',
    loading: false,
    customer: checkedJsonStorage('customer') || {},
    cart: checkedJsonStorage('cart') || {},
    order: JSON.parse(localStorage.getItem('order')) || {},
    useCart: false,
    transaction: {
      tradingPair: '',
      quantity: 0,
      estimatedRate: 0,
      estimatedFee: 0,
      locked:"fiat"
    },
    currencies: {},
    transactionType: Trx_type && ["buy","sell"].includes(Trx_type.toLowerCase()) ? Trx_type : "BUY"
  });

  const isValidTransaction = Boolean(
    state.transaction.tradingPair &&
      state.transaction.quantity &&
      state.transaction.estimatedFee &&
      state.transactionType,
  );

  function ShowError(){
    if(state.error){
      return <Alert message={state.error} type="alert" okay={()=>clearError(dispatch)} />
    }
    return null
  }

 
  return (
    <TransactionStateContext.Provider value={{ ...state, isValidTransaction }}>
      <TransactionDispatchContext.Provider value={dispatch}>
       <ShowError />
        {children}
      </TransactionDispatchContext.Provider>
    </TransactionStateContext.Provider>
  );
}

function useTransactionState() {
  var context = React.useContext(TransactionStateContext);
  if (context === undefined) {
    throw new Error(
      'useTransactionState must be used within a TransactionProvider',
    );
  }
  return context;
}

function useTransactionDispatch() {
  var context = React.useContext(TransactionDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useTransactionDispatch must be used within a TransactionProvider',
    );
  }
  return context;
}

export { TransactionProvider, useTransactionState, useTransactionDispatch };
