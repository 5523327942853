import Axios from "axios";
import {
  BASE_BUY_API,
  BASE_SELL_API,
  BASE_VERIFY_API,
} from "./transaction.constants";
export const transactionService = {
  createTransaction,
  createOrder,
  verifyOrder,
  createVerify,
  updateSellTransaction,
  completeVerify,
  generateSellWallet
};
const requestOptions = {
  accept: "*/*",
};

const Schema = {
  buy: BASE_BUY_API,
  sell: BASE_SELL_API,
};

/**
 * 
 * @param {*} data 
 * @param {*} type 
 */
function createTransaction(data, type = "buy") {
  return Axios.post(
    `${Schema[type.toLowerCase()]}/create`,
    data,
    requestOptions
  ).then((response) => {
    localStorage.setItem("cart", JSON.stringify({...response.data,  updatedAt:new Date()}));
    return response.data;
  });
}

/**
 * 
 * @param {*} cart 
 * @param {*} type 
 */
function createOrder(cart, type = "buy") {
  return Axios.post(`${Schema[type.toLowerCase()]}/order/create`, cart, requestOptions).then(
    (response) => {
      localStorage.setItem("order", JSON.stringify(response.data));
      return response.data.data;
    }
  );
}

/**
 * 
 * @param {*} trxref 
 */
function verifyOrder(trxref) {
  return Axios.get(`${BASE_BUY_API}/verify/${trxref}`, requestOptions).then(
    (response) => {
      localStorage.setItem("order", JSON.stringify(response.data));
      return response.data;
    }
  );
}

/**
 * 
 * @param {*} customer 
 */
function createVerify(customer) {
  return Axios.post(`${BASE_VERIFY_API}/create`, customer).then((response) => {
    return response.data;
  });
}
/**
 * 
 * @param {*} otp 
 */
function completeVerify(otp) {
  return Axios.post(`${BASE_VERIFY_API}/complete`, otp).then((response) => {
    return response.data;
  });
}

/**
 * 
 * @param {*} trxref 
 * @param {*} data 
 */
function updateSellTransaction(trxref, data) {
  return Axios.put(
    `${BASE_SELL_API}/update/${trxref}`,
    data,
    requestOptions
  ).then((response) => {
    localStorage.setItem("cart", JSON.stringify({...response.data,  updatedAt:new Date()}));
    return response.data;
  });
}

function generateSellWallet(trxref){
  return Axios.get( `${BASE_SELL_API}/generate/address/${trxref}`,requestOptions).then(response=>{
    localStorage.setItem("cart", JSON.stringify({...response.data, updatedAt:new Date()}));
    return response.data;
  })
}
