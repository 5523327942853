import { useCallback, useEffect, useState, useRef } from 'react';
import { setNativeValue, fetchRates, getLiveFees } from './utils';
import BigNumber from 'bignumber.js';
import * as Yup from 'yup';

//VALIDATION SCHEMA FOR FIATVALUE
const requiredFiatNumber = Yup.number()
  .typeError('Please provide a valid amount')
  .min(50000, "Order value can't be lower than N50,000")
  .max(500000, "Order value can't be higher than N500,000")
  .required('*');

//VALIDATION SCHEMA FOR CRYPTOVALUE
const requiredNumber = Yup.number()
  .typeError('Please provide a valid amount')
  .min(0, 'invalid value')
  .required('*');

//significant figure for CRYPTO
const CRYPTOSGF = 7;

/**
 * useRateConverter 
 * © Afriswap
 * @param {*} param0 
 */

function useRateConverter({
  defaultOption = {},
  defaultVal = {},
  defaultLock = 'fiat',
}) {

  const [rates, setRates] = useState([]);
  const [fees, setFees] = useState([]);
  const [fee,setFee] = useState({
     feeInFiat:0,
     feeInCrypto:0
  });
  const lockSchema = {
    fiat:"fiat",
    crypto:"crypto"
  };
  const [transType, setTransType] = useState('buy');
  const [error, setError] = useState({
    crypto: '',
    fiat: '',
    others: '',
  });
  const fiatInput = useRef(null);
  const cryptoInput = useRef(null);
  const [lock, setLock] = useState(lockSchema[defaultLock] || "fiat");
  const RATE_INTERVAL = 30 * 1000;   //30 SECONDS
  const FEE_INTERVAL = 1000 * 60 * 5 //five minutes
  const [bases, setBases] = useState({
    fiat: defaultOption.fiat || 'NGN',
    crypto: defaultOption.crypto || 'BTC',
  });

  function getLiveRates(transType) {
    fetchRates(transType)
      .then((data) => {
        setRates(data);
      })
      .catch((error) => {
        console.log(`we could not get rate :( =>${error}`);
      });
  }

 function getFees() {
    getLiveFees()
      .then((data) => {
        setFees(data);
      })
      .catch((error) => {
        console.log(`we could not get fees :( =>${error}`);
      });
  }

  function manuallyTriggerOnchange(ref) {
    if (ref && ref.current) {
      const previousValue = ref.current.value; // saved current value because
      ref.current.value = 0; // react will not fire onchange unless current
      setNativeValue(ref.current, previousValue); // input has realy changed
      ref.current.dispatchEvent(new window.Event('change', { bubbles: true }));
    }
  }

  function validateInput(point, value) {
    try {
      if (point == 'fiat') {
        requiredFiatNumber.validateSync(value);
      } else {
        requiredNumber.validateSync(value);
      }
      setError((prev) => ({ ...prev, [point]: `` }));
    } catch (error) {
      setError((prev) => ({
        ...prev,
        [point]: error.message || `invalid value`,
      }));
    }
  }

  function updateFiat(cryptoValue,considerFee) {
    if (fiatInput.current === document.activeElement) {
      return;
    }

    if( considerFee && transType.toUpperCase() != "SELL"){
      //fee calculation should be done on the future value based on the current crypto value
     // console.log("in update fee",transType )
      const fiatValue = parseFloat(converter(bases.crypto, cryptoValue));
      const fee = calculateFees(fiatValue);
      fiatInput.current.value = BigNumber(fiatValue + fee.feeInFiat).toFixed(2);
      validateInput('fiat', fiatInput.current.value);
      setFee(fee);
      return 
    }

    fiatInput.current.value = converter(bases.crypto, cryptoValue);
    validateInput('fiat', fiatInput.current.value);
    return;
  }

  function updateCrypto(fiatValue, considerFee, message) {
    // console.log(message)
    if (cryptoInput.current === document.activeElement) {
      return;
    }

    //DONT CALCULATE FEE FOR SELL
    if( considerFee && transType.toUpperCase() != "SELL"){
      const fee = calculateFees(fiatValue);
      cryptoInput.current.value = BigNumber(parseFloat(converter(bases.crypto, fiatValue, true)) - fee.feeInCrypto).toFixed(CRYPTOSGF);
      validateInput('crypto', cryptoInput.current.value);
      setFee(fee);
      return 
    }

    cryptoInput.current.value=converter(bases.crypto, fiatValue, true);
    validateInput('crypto', cryptoInput.current.value);
    return   
  }

  function safelyParse(value) {
    const parsed = parseFloat(value);
    return Number.isNaN(parsed) ? 0.0 : parsed;
  }

  function getRef(point) {
    if (point === 'crypto') return cryptoInput;
    if (point === 'fiat') return fiatInput;
    return null;
  }

  useEffect(() => {
    getLiveRates(transType);
    getFees();
    var liveId = setInterval(() => getLiveRates(transType), RATE_INTERVAL);
    var feeId  = setInterval(() => getFees(), FEE_INTERVAL)
    return () => clearInterval(liveId), clearInterval(feeId);
  }, [transType]);

  //update crypto whenever fee value changes
  // useEffect(()=>{
  //   if(fiatInput.current){
  //     //updateCrypto(fiatInput.current.value);
  //   }  
  // },[fees])

  //retriggers the onchange handler of fiatinput once base choice changes
  useEffect(() => {
    //trigger the onchange of the locked pair
    manuallyTriggerOnchange(getRef(lock));
  }, [bases,transType]);

 //update unlocked crypto on rate changes, with fee inclusives
  useEffect(() => {
    if (cryptoInput.current && fiatInput.current && rates.length) {

      //if lock is fiat, add fee to crypto
      if (lock === 'fiat') {
        updateCrypto(fiatInput.current.value,true, "in fiatLock");
        return;
      }

     //if lock is crypto, add fee to fiat
      if (lock === 'crypto') {
        updateFiat(cryptoInput.current.value,true);
        return;
      }

    }
  }, [rates]);


/**
 * 
 */
  const setBase = useCallback(
    (name, value) =>
      setBases((prev) => ({
        ...prev,
        [name]: value,
      })),
    [],
  );


/**
 * 
 * @param {*} param0 
 */
  const setValues = ({ base, fiat }) => {
    if (fiat && fiatInput.current) {
      fiatInput.current.value = fiat;
      cryptoInput.current.value = converter(base || bases.crypto, fiat, true);
    }
  };


/**
 * 
 */
  const getRates = useCallback(
    (name) => {
      const crypto = rates.find((rate) => {
        return rate.crypto.toLowerCase() === name.toLowerCase();
      });
      if (crypto) {
        setError((prev) => ({
          ...prev,
          others: ``,
        }));
        return parseFloat(crypto.tradingPairPrice.price);
      }
      setError((prev) => ({
        ...prev,
        others: `we currently do not support ${name} coin`,
      }));
      return 0.0;
    },
    [rates],
  );


  /**
   * 
   */
  const converter = useCallback(
    (base = 'BTC', value, reverse = false) => {
      value = value && value !== null ? parseFloat(value) : 0.0;
      if (Number.isNaN(value)) {
        // if (process.env.NODE_ENV === 'development')
        //   throw new Error('value cannot be NaN in converter');
        value = 0;
      }

      const rate = new BigNumber(getRates(base));
      value = new BigNumber(value);
      if (reverse) {
        if (rate <= 0) {
          return 0.0;
        } // this is to prevent infinity when divisor is 0
        return rate ? value.dividedBy(rate).toFixed(CRYPTOSGF) : 0.0;
      }
      return value.times(rate).toFixed(2);
      // eslint-disable-next-line
    },
    [getRates],
  );


  /**
   * 
   * @param {*} fiatValue 
   */
  function calculateFees(fiatValue){
    const Fee_Object = {
      feeInFiat:0,
      feeInCrypto:0,
      isvalid:false, // for error indication
    }
    
    //if fee has not been loaded return invalid fee
    if(!fees || !fees.length)return Fee_Object;

    //look for the fee percent that fiat value falls within  the range
    const picked = fees.find(fee => fiatValue <= fee?.upperLimit && fiatValue >= fee?.lowerLimit);
    if(!picked){
      //value is not within range
      return Fee_Object;
    }

    Fee_Object.feeInFiat = fiatValue * (picked?.feePercent/100);
    Fee_Object.feeInCrypto = parseFloat(converter(bases.crypto,Fee_Object.feeInFiat,true));
    Fee_Object.isvalid = true;
    return Fee_Object;
  
  }


  /**
   * 
   */
  const getDefaultVal = useCallback(
    (point) => {
      const { crypto, fiat } = defaultVal;
      if (point === 'crypto') return crypto;
      if (point === 'fiat') return fiat;
      return null;
    },
    [defaultVal],
  );


  /**
   * 
   */
  const getInputProps = useCallback(
    (point) => {
      return {
        onChange: (e) => {
          const { value } = e.target;
          validateInput(point, value);
          if (point === 'crypto' && fiatInput.current) {
            return updateFiat(value, lock === "crypto");
          }
          if (point === 'fiat' && cryptoInput.current) {
            return updateCrypto(value, lock === "fiat","from change");
          }
        },
        onBlur:()=>{
             /**
              * if user blurs out of fiat input
              * and fiat is not locked
              * grab crypto input value and update fiat with fees
              */
            if(point === "fiat" && lock === "crypto" && cryptoInput.current ){
             return updateFiat(cryptoInput.current.value,true);
            }
            /**
              * if user blurs out of crypto input
              * and crypto is not locked
              * grab fiat input value and update crypto with  fees
              */
            if(point === "crypto" && lock === "fiat" && fiatInput.current){
              updateCrypto(fiatInput.current.value, true);
            }
        },
        ref: getRef(point),
        defaultValue: getDefaultVal(point),
        disabled: !rates.length,
      };
      // eslint-disable-next-line
    },
    [bases, converter, getDefaultVal, rates],
  );

  const values = {
    fiat: fiatInput.current ? safelyParse(fiatInput.current.value) : 0.0,
    crypto: cryptoInput.current ? safelyParse(cryptoInput.current.value) : 0.0,
  };

  return {
    getInputProps,
    rates,
    setBase,
    error,
    fee,
    values,
    setValues,
    locked:lock,
    setLock,
    setTransType,
  };
}

export default useRateConverter;
