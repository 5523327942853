import React, { Fragment, useState, useEffect } from 'react';
import styles from './index.module.scss';
import Oldstyle from '../../styles.module.scss';
import cx from 'classnames';
import BigNumber from 'bignumber.js';
import PropTypes from 'prop-types';
import {
  useTransactionState,
  useTransactionDispatch,
  createOrder,
  clearExpiredCart,
} from '../../factory';
import Alert from '../Alert/Alert';
import _ from 'lodash';

function TreviewCard({ next, hideAction, callbackUrl, embeded, openChat }) {
  const { loading, cart } = useTransactionState();
  const type = cart.transactionType;
  const tDispatch = useTransactionDispatch();
  const [showClearAlert, setShowClearAlert] = useState(false);
  const checkCart = clearExpiredCart(tDispatch);

  useEffect(() => {
    if (cart) {
      if (checkCart.isExpired) {
        setShowClearAlert(true);
      }
    }
  }, [cart]);

  function handleBuyOrder() {
    createOrder(
      tDispatch,
      {
        transactionRef: cart.transactionRef,
        transactionType: type.toUpperCase(),
        callbackUrl: callbackUrl || window.location.href.split('?')[0], //use current url without query strings
      },
      type,
    ).then((ok) => {
      if (type.toUpperCase() == 'buy') {
        return;
      }
      next();
    });
  }

  function handleConfirmOrder() {
    handleBuyOrder();
  }

  function HeadLight() {
    if (!cart || _.isEmpty(cart)) return null;
    if (type.toLowerCase() === 'sell') {
      return (
        <>
          <small>
            You want to sell {BigNumber(cart.cryptoToSell).toFormat(7)}
            {cart.crypto}{' '}
          </small>
          <h4>
            at {cart.crypto}/{cart.fiat}{' '}
            {`N ${BigNumber(cart.amountToPay).toFormat(3)}`}
          </h4>
        </>
      );
    }

    if (type.toLowerCase() === 'buy') {
      return (
        <>
          <small>
            You will get{' '}
            {`${BigNumber(cart.quantity).toFormat(7)} ${cart.crypto}`}
          </small>
          <h4>
            at {cart.crypto}/{cart.fiat}{' '}
            {`N ${BigNumber(cart.price).toFormat(3)}`}
          </h4>
        </>
      );
    }

    return null;
  }

  function ActionText() {
    if (!cart || _.isEmpty(cart)) return null;
    if (loading) return <i className='fa fa-spinner fa-spin'></i>;
    if (type.toLowerCase() === 'buy') return `PAY ₦${cart.price}`;
    else if (type.toLowerCase() === 'sell')
      return `SELL ${BigNumber(cart.cryptoToSell)} ${cart.crypto}`;
  }

  return (
    <div
      className={cx(
        styles.treview,
        Oldstyle.swap_calculator,
        Oldstyle.no_shadow,
        embeded && Oldstyle.embeded
      )}
    >
      <div className={styles.header}>
        <h3>CONFIRM ORDER</h3>
        <HeadLight />
      </div>
      <div className={styles.tsummary}>
        <TSummary type={type} />
      </div>
      {!hideAction && (
        <button
          className={Oldstyle.btn_action}
          onClick={handleConfirmOrder}
          style={{ marginTop: 0 }}
        >
          <ActionText />
        </button>
      )}
      <div className={Oldstyle.chat}>
        <span onClick={openChat}>
          <i className='fa fa-comments-o'></i>
          <span className={styles.chat__action}>
            <u>chat with us</u>
          </span>
        </span>
      </div>
      {showClearAlert && (
        <Alert
          type='confirm'
          message={"transaction has expired. start a new transaction"}
          yes={() => checkCart.clear()}
          no={() => setShowClearAlert(false)}
        />
      )}
    </div>
  );

  function TSummary({ type }) {
    if (!cart || _.isEmpty(cart)) return null;
    switch (type.toLowerCase()) {
      case 'buy':
        return (
          <>
            <p>
              <span>Crypto</span>
              <span>{cart.crypto}</span>
            </p>
            <p>
              <span>Address</span>
              <span>{cart.wallet.address}</span>
            </p>
            <p>
              <span>Value</span>
              <span>
                {cart.crypto}&nbsp;{BigNumber(cart.quantity).toFormat(7)}
              </span>
            </p>
            ``
            <p>
              <span>Amount</span>
              <span>{`N ${new BigNumber(cart.price).toFormat(3)}`}</span>
            </p>
            <div className={styles.total}>
              <p>
                <span>Total</span>
                <span className={styles.tfigure}>₦{cart.price}</span>
              </p>
            </div>
          </>
        );
      case 'sell':
        return (
          <>
            <p>
              <span>Bank Name</span>
              <span>{cart.accountDetails?.bankName}</span>
            </p>

            <p>
              <span> Account Number </span>
              <span>{cart.accountDetails?.accountNumber}</span>
            </p>

            <p>
              <span>Account Name </span>
              <span>{cart.accountDetails?.accountName}</span>
            </p>
            <p>
              <span>Amount </span>
              <span>
                {`${cart?.cryptoToSell} ${cart.crypto}`}=N{' '}
                {new BigNumber(cart.amountToPay).toFormat(3)}
              </span>
            </p>
            <div className={styles.total}>
              <p>
                <span>Total</span>
                <span className='tfigure'>{`${cart.cryptoToSell} ${cart.crypto}`}</span>
              </p>
            </div>
          </>
        );
      default:
        return null;
    }
  }
}

TreviewCard.defaultProps ={
  openChat: ()=>{}
}
TreviewCard.propTypes = {
  next: PropTypes.func,
  hideAction: PropTypes.bool,
  callbackUrl: PropTypes.string,
};

export default TreviewCard;
