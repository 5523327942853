import * as Yup from 'yup';
import CAValidator from 'cryptocurrency-address-validator';

/** CUSTOM VALIDATION METHOD FOR CRYPTO */
Yup.addMethod(Yup.string, 'crypto', function (message) {
  return this.test('cryptovalidation', message, function (value) {
    const { path, createError } = this;
 
    ///currency must be a property of the validated object
    if (!this.parent.currency) {
      return createError({
        path,
        message: 'parent object must have a valid crypto currency property',
      });
    }

    //use Eth validation for all erc20 coin
    const erc20 = ['ETH', 'USDT', 'LINK'];
    try {
      if (erc20.includes(this.parent.currency.toUpperCase())) {
        return (
          CAValidator.validate(value.trim(), 'ETH') ||
          createError({ path, message })
        );
      }
      return (
        CAValidator.validate(value.trim(), this.parent.currency, 'both') ||
        createError({ path, message })
      );
    } catch (err) {
      return false;
    }
  });

});

const requiredString = Yup.string().required();

/*** CONTACT VALIDATION SCHEMA*/
export const contactSchema = Yup.object().shape({
  customer: Yup.object().shape({
    option: Yup.string().required('You must pick a contact option'),
    email: Yup.string().when('option', {
      is: 'email',
      then: Yup.string()
        .email('email is not valid')
        .required('email is required'),
    }),
    phoneNumber: Yup.string().when('option', {
      is: 'phoneNumber',
      then: Yup.string().length(14).required(),
    }),
  }),
  isSubscribed: Yup.bool(),
  acceptedTermsAndCondition: Yup.bool()
    .oneOf([true])
    .required('you mmust agree to terms and conditions'),
});

//WALLET VALIDATION SCHEMA
export const wallet = Yup.object().shape({
  address: Yup.string()
    .crypto('wallet address is invalid')
    .required('wallet address is required'),
  currency: requiredString,
});

//BANK VALIDATION SCHEMA
export const bankSchema = Yup.object().shape({
  isValidAccount: Yup.bool().oneOf([true], 'it must be a valid account'),
  accountDetails: Yup.object().shape({
    accountName: requiredString,
    accountNumber: Yup.string()
      .required()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .max(10, 'Must be exactly 5 digits'),
    bankName: requiredString,
    bankCode: requiredString,
  }),
});


export default buySchema;
