import React from 'react'
import useRateConverter from '../../useRateConverter';

const formatPrice = number =>
{
    const value = new Intl.NumberFormat( 'en-GB').format( number );

    return `₦${ value }`;
};

function RateBtn(props) {
    const {
        rates:coins,
      } = useRateConverter({});
     coins.sort((a,b)=> a.crypto < b.crypto ? -1 : a.crypto > b.crypto ? 1 : 0)
    return (
          <div className={props.selectClass || ""}>
          <select >
                            {
                                coins.map( c => <option
                                    key={ c?.name }
                                    value={ c?.name } > 1 { c?.crypto } = {c.tradingPairPrice? formatPrice( c?.tradingPairPrice.price ) : 0.000} </option>
                                )
                            }
        </select>  
        </div>
    )
}

RateBtn.propTypes = {

}

export default RateBtn

