import React from 'react';
import styles from './scss/styles.module.scss';
import Oldstyle from '../../styles.module.scss';
import { useFormik } from 'formik';
import { wallet } from './ValidationSchema';
import FormState from './FormState';
import cx from 'classnames';
import { useUnload } from '../Hooks';

//import PropTypes from 'prop-types'

function WalletVerification({ onVerified, type }) {
  const initialValues = {
    address: '',
    currency: type,
  };

  useUnload((e) => {
    e.preventDefault();
    const message = 'your changes might be lost';
    e.returnValue = message;
    return message;
  });

  const {
    errors,
    touched,
    values,
    getFieldProps,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues,
    initialErrors: initialValues,
    validationSchema: wallet,
    onSubmit: () => {
      onVerified(values);
    },
  });

  const checkError = () => errors['address'] && touched['address'];

  function getFeedBack() {
    if (isValid) {
      return <FormState type='okay' message={`wallet address verified`} />;
    }

    return (
      <FormState
        type='info'
        message={`Please , kindly ensure you input the right address for your
  cryptocurrency. e.g, do not input an ETH wallet address for a BTC buy
  order`}
      />
    );
  }

  return (
    <div className={styles.verify}>
      <h3 className={styles.title}>
        LET'S GET YOU STARTED WITH YOUR WALLET ADDRESS
      </h3>
      <form onSubmit={handleSubmit}>
        <div className={styles.control_region}>
          <div className={styles.form_control}>
            <label className={cx(checkError() && styles.error)}>
              Enter your {type} wallet address below
            </label>

            <input
              type='text'
              name='address'
              {...getFieldProps('address')}
              className={cx(
                errors.address && touched.address && styles.input_error,
              )}
            />
          </div>
        </div>

        <div className={styles.infoarena}>
          {checkError() ? (
            <FormState
              type='error'
              message='You have entered an invalid wallet address. Please check that the address is correct and try again'
            />
          ) : (
            getFeedBack()
          )}
        </div>

        <button
          type='submit'
          className={cx(
            Oldstyle.btn_action,
            !isValid && Oldstyle['btn-disabled'],
          )}
        >
          PROCEED
        </button>
      </form>
    </div>
  );
}

WalletVerification.propTypes = {};

export default WalletVerification;
