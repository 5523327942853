import React, { useRef } from 'react';
import Oldstyle from '../../styles.module.scss';
import styles from './scss/styles.module.scss';
import cx from 'classnames';
import { useTransactionState } from '../../factory';


function ViewWallet({next}) {
  const { cart } = useTransactionState();
  const walletCopy = useRef(null);
  
  function handleCopy() {
    if (walletCopy.current) {
      walletCopy.current.select();
      walletCopy.current.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand('copy');
    }
  }

  return (
    <div className={styles.verify}>
      <div className={styles.view_wallet}>
      <h4 className={styles.title}>SEND US {cart.crypto}</h4>
      <p className={styles.pinfo}>
      Please send {cart?.cryptoToSell} {cart.crypto} to the Afriswap Bitcoin Wallet Address below. 
      </p>
      <div className={`${styles.form_control} ${styles.with_icon}`}>
        <label>Afriswap Wallet Address</label>
        <input
          type='text'
          readOnly
          value={cart.generatedWallet?.address}
          ref={walletCopy}
        />
        <span className={styles.icon} onClick={handleCopy}>
          <i className='fa fa-copy'></i>
        </span>
      </div>
      <div className={styles.barcode}>
        <p style={{color:"#dc0000", marginBottom:10}}>Bitcoin price refreshes in 30 seconds</p>
        <label>Naira will be credited after confirmation</label>
        <div className={styles.block}>
          <img
            src='https://miro.medium.com/max/1424/1*sHmqYIYMV_C3TUhucHrT4w.png'
            alt='qrcode'
          />
        </div>
      </div>
      <h5 className={styles.checkpoint}>sent crypto to Afriswap?</h5>
      <button className={cx(Oldstyle.btn_action)} style={{marginTop:8}} onClick={next}>PROCEED</button>
    </div>
    </div>
  );
}

ViewWallet.propTypes = {};
ViewWallet.defaultProps = {
  next:()=>{}
};

export default ViewWallet;
