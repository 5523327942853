import React from 'react';
import styles from './alert.module.scss';
import Overlay from '../OverLay/OverLay';
import { ReactComponent as Loader } from './load.svg';
import { ReactComponent as Error } from "../../assets/img/error.svg";
import PropTypes from 'prop-types';

function Actions({ state, yes, no, okay }) {
  if (state === 'alert') {
    return (
      <button className={`${styles.button} ${styles.okay} `} onClick={okay}>
        okay
      </button>
    );
  }

  if (state === 'confirm') {
    return (
      <div className={styles.confirm}>
        <button className={styles.button} onClick={yes}>
          yes
        </button>
        <button className={`${styles.button} ${styles.ghost}`} onClick={no}>
          no
        </button>
      </div>
    );
  }

  return null;
}

function Alert({ type, icon, yes, no, okay, message }) {
  return (
    <Overlay>
      <div className={styles.alert}>
        <div className={styles.icon}>
        {icon === 'loading' && (<span><i className="fa fa-spinner fa-spin"></i></span>)}
        {icon === 'warning' && <Error />}
        </div>
        <p>{message}</p>

        <Actions state={type} yes={yes} no={no} okay={okay} />
      </div>
    </Overlay>
  );
}

Alert.propTypes = {};

export default Alert;
