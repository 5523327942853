const DEV_CONFIG = {
    REACT_APP_NAME:"Afriswap exchange app",
    REACT_APP_DESCRIPTION:"This is afriswap for currency exchange",
    REACT_APP_SOURCE_URL:"https://github.com/BlockAfric/fe-afriswap.git",
    REACT_APP_API_URL:"https://api.dev.afriswap.io/api",
    REACT_APP_FRONTEND_URL:"http://localhost:3000",
    //REACT_APP_COIN
}
const PRODUCTION_CONFIG = {
    REACT_APP_NAME:"Afriswap exchange app",
    REACT_APP_DESCRIPTION:"This is afriswap for currency exchange",
    REACT_APP_SOURCE_URL:"https://github.com/BlockAfric/fe-afriswap.git",
    REACT_APP_API_URL:"https://api.dev.afriswap.io/api",
    REACT_APP_FRONTEND_URL:"https://test.afriswap.io"
}
const MAIN_CONFIG = process.env.NODE_ENV == "production" ? PRODUCTION_CONFIG : DEV_CONFIG;
export default MAIN_CONFIG;