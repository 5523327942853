import Axios from "axios";

export const isMobile = (name) =>
  window.matchMedia("(max-width: 600px)").matches;
export function verifyAccounNumber(accountNo, Bank_Code) {
  return Axios.get(
    `https://api.dev.afriswap.io/api/utils/bank/verifyAccount/?bankCode=${Bank_Code}&accountNumber=${accountNo}`,
    {
      accept: "*/*",
    }
  );
}

export function remove_character(str, char_pos) 
 {
  let part1 = str.substring(0, char_pos);
  let part2 = str.substring(char_pos + 1, str.length);
  console.log(part1,part2,char_pos)
  return (part1 + part2);
 }

 export function findMinutesDiff(dt1,dt2){
  var diff =(dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
 }